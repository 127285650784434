// eslint-disable-next-line import/no-unresolved
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useContextTheme } from '../../constants/context';

import { Props } from './types';

export function SEO({
  article,
  description,
  image,
  lang = 'en',
  title,
}: Props) {
  const [hasSwitchedTheme, setHasSwitchedTheme] = useState(false);

  const [isThemeAlt] = useContextTheme();
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const classNames = useMemo(() => {
    let classNames = [];
    if (hasSwitchedTheme) {
      classNames = [...classNames, 'switching-theme'];
    }
    if (isThemeAlt) {
      classNames = [...classNames, 'theme-alt'];
    }
    return classNames;
  }, [hasSwitchedTheme, isThemeAlt])

  const {
    defaultDescription,
    defaultImage,
    defaultTitle,
    siteUrl,
    titleTemplate,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    title: title || defaultTitle,
    url: `${siteUrl}${pathname}`,
  }

  useEffect(() => {
    setHasSwitchedTheme(true);
    setTimeout(() => {
      setHasSwitchedTheme(false);
    }, 300);
  }, [isThemeAlt]);

  return (
    <Helmet
      htmlAttributes={{
        ...(classNames.length > 0 ? { class: classNames.join(' ') } : {}),
        lang,
      }}
      title={seo.title}
      titleTemplate={titleTemplate}
    >
      <meta content={seo.description} name="description" />
      <meta content={seo.image} name="image" />
      {seo.url && <meta content={seo.url} property="og:url" />}
      {article && <meta content="article" property="og:type" />}
      {seo.title && <meta content={seo.title} property="og:title" />}
      {seo.description && (
        <meta content={seo.description} property="og:description" />
      )}
      {seo.image && <meta content={seo.image} property="og:image" />}
      <meta content="summary_large_image" name="twitter:card" />
      {twitterUsername && (
        <meta content={twitterUsername} name="twitter:creator" />
      )}
      {seo.title && <meta content={seo.title} name="twitter:title" />}
      {seo.description && (
        <meta content={seo.description} name="twitter:description" />
      )}
      {seo.image && <meta content={seo.image} name="twitter:image" />}
    </Helmet>
  )
} 

export const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultDescription: description
        defaultImage: image
        defaultTitle: title
        siteUrl
        titleTemplate
        twitterUsername
      }
    }
  }
`
